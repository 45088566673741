<template>
  <div>
    <div class="page-header d-flex  align-items-center">
      <h3 class="page-header-title">Email Notification Logs</h3>
    </div>
    <div class="page-content p-normal">
      <div class="d-flex justify-content-between">
        <div class="search-wrapper">
          <img src="@/assets/icons/icon-search.svg" alt="Icon search">
          <input type="text" placeholder="Search" v-model="filter.query" @input="logSearch" aria-label="Search">
        </div>
        <div class="d-flex table-filter">
          <div class="form-group full-width">
              <multiselect v-model="filter.type"
                          track-by="value"
                          label="label"
                          class="full-width"
                          open-direction="bottom"
                          :options="formData.types"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          @select="onTypeChange"
                          placeholder="Select Type">
              </multiselect>
          </div>
          <div class="form-group full-width ms-2">
              <multiselect v-model="filter.status"
                          track-by="value"
                          label="label"
                          class="full-width"
                          open-direction="bottom"
                          :options="formData.status"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          @select="onStatusChange"
                          placeholder="Select Status">
              </multiselect>
          </div>
        </div>
      </div>
      <div class="table table-progress">
        <div class="text-center mt-4" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <table class="table table-progress" v-else>
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>To</th>
              <th>From</th>
              <th>Content</th>
              <th>Status</th>
              <th>Sent</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in emailLogs" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ log.type }}</td>
              <td>{{ log.receiver }}</td>
              <td>{{ log.sender }}</td>
              <td>
                <button class="btn btn-control ms-2"
                        content="Show Content"
                        @click="showContent = true; emailLog = log"
                        v-tippy="{ placement : 'bottom', arrow : true, theme : 'dark', arrowType : 'round' }">
                  <img src="@/assets/icons/icon-email.svg" alt="">
                </button>
              </td>
              <td :class="log.status ? 'text-success' : 'text-danger'">{{ log.status ? 'Delivered' : 'Undelivered' }}</td>
              <td>{{ log.createdAt | formateDate }}</td>
            </tr>
          </tbody>
        </table>
        <div class="col-12 modal-alert modal-alert_advice text-center" v-if="emailLogs.length === 0 && !loading">
          No Results Found
        </div>
      </div>
      <Paging :paging="paging" @pageChanged="pagingUpdated" @limitChanged="pagingUpdated"></Paging>
    </div>
    <transition name="component-fade" mode="out-in">
      <ShowContent :emailLog="emailLog" @close="showContent = false" v-if="showContent"/>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from 'vue-multiselect';
import Paging from "@/components/Paging";
import debounce from "../../utils/debounce";
import ShowContent from "./modals/ShowContent"
export default {
  name: 'EmailLogs',
  components: { Multiselect, Paging, ShowContent },
  data() {
    return {
      loading: Boolean,
      paging: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      filter: {
        query: '',
        type: { value: '', label: 'All Types' },
        status: { value: 0, label: 'All' }
      },
      formData: {
        types: [],
        status: [
          {
            value: 0,
            label: 'All'
          },
          {
            value: 1,
            label: 'Delivered'
          },
          {
            value: 2,
            label: 'Undelivered'
          }
        ]
      },
      emailLogs: [],
      showContent: false,
      emailLog: {},
      search: debounce(() => {
        this.paging.offset = 0
        this.loadLogs()
      }, 350),
    }
  },
  methods: {
    loadLogs() {
      this.loading = true
      this.$http.get('/api/v1/email/logs',
        {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit,
            type: this.filter.type?.value,
            status: this.filter.status?.value,
            query: this.filter.query,
          }
        })
        .then((res) => {
          this.emailLogs = res.data.data
          this.formData.types = res.data.types
          this.formData.types.unshift({
            value: '',
            label: 'All Types'
          })
          this.paging = res.data.paging
        })
        .catch((err) => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: err.response.statusText,
            status: 'error',
            delay: 2000
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
    onTypeChange(type) {
      this.filter.type = type
      this.paging.offset = 0
      this.loadLogs()
    },
    onStatusChange(status) {
      this.filter.status = status
      this.paging.offset = 0
      this.loadLogs()
    },
    logSearch() {
      this.search()
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.loadLogs();
    },
  },
  filters: {
    formateDate: function (value) {
      if (value) {
        return moment(String(value)).format('MMM D, Y, h:mm a')
      }
    }
  },
  created() {
    this.loadLogs()
  }
}
</script>

<style lang="scss" scoped>
.table-filter::v-deep .multiselect {
  min-height: 36px;
  max-height: 36px;
  min-width: 180px;
  max-width: 180px;
  width: fit-content;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }
  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    min-height: 36px;
    max-height: 36px;
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    min-height: 36px;
    max-height: 36px;
    &:before {
      top: 10%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
