<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block">
        <div class="title modal-body-container mb-0">
          <button class="btn btn-control close-btn" @click="$emit('close')">
            <img alt="" src="@/assets/icons/icon-close.png">
          </button>
          {{ emailLog.type }}
          <img v-if="emailLog.status" alt="success" class="ms-2" src="@/assets/icons/icon-status-ok.svg">
          <img v-else alt="error" class="ms-2" src="@/assets/icons/icon-status-error.svg">
          <p class="loan-file">Email Content</p>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="details" v-html="emailLog.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowContent",
  props: {
    emailLog: Object
  }
}
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: auto;
    padding: 20px;
    margin: 5vh auto;
    overflow: hidden !important;
    border-radius: 8px;
  }

  .modal-body {
    padding: 30px;
    height: 80vh;
    overflow: auto;
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    padding: 5px 30px;

    .loan-file {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .status {
      box-sizing: border-box;
      border: 1px solid #0076FF;
      border-radius: 12px;
      background-color: #FFFFFF;
      color: #0078FF;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      padding: .4rem;
      margin-left: 1rem;
    }
  }

  .close-btn {
    float: right;

    &:hover {
      background-color: #FFF;
    }
  }

  .details {
    p {
      color: #000000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
</style>
